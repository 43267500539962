<template>
  <section>
    <header class="header">
      <v-container class="d-flex justify-space-between align-center">
        <template v-if="simple">
          <a href="/">
            <img class="header__logo" src="@/static/images/Vector111.svg" />
          </a>
        </template>
        <template v-else>
          <RouterLink to="#home"
            ><img class="header__logo" src="@/static/images/Vector111.svg"
          /></RouterLink>

          <nav class="header__navigation d-none d-md-flex">
            <RouterLink
              v-for="(item, key) in CONFIG.nav"
              :key="key"
              :to="item.link"
              class="header__navigation__link"
            >
              {{ item.text }}
            </RouterLink>
          </nav>

          <img
            src="@/static/images/Связаться с нами -_.svg"
            class="header__button d-none d-md-flex"
            @click="$root.$emit('show-modal')"
          />

          <div
            class="header__burger d-lg-none"
            @click="menuIsActive = !menuIsActive"
          >
            <span
              v-for="n in 3"
              :key="n"
              class="header__burger-item tertiaryText"
              :class="{ 'header__burger-item_active': menuIsActive }"
            />
          </div>
        </template>
      </v-container>
    </header>

    <v-navigation-drawer
      v-if="!simple"
      v-model="menuIsActive"
      absolute
      temporary
      class="bSidebar"
    >
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in CONFIG.nav"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>

<script>
import { ModalDefault } from "@/components/modals"

const CONFIG = Object.freeze({
  icon: "@/assets/svg/logo.svg",
  nav: [
    {
      text: "О продукте",
      link: "#about",
    },
    {
      text: "Для кого",
      link: "#who",
    },
    {
      text: "Возможности",
      link: "#opportunities",
    },
    {
      text: "Стоимость",
      link: "#price",
    },
    {
      text: "О компании",
      link: "#company",
    },
    {
      text: "FAQ",
      link: "#faq",
    },
  ],
})

export default {
  name: "HeaderDefault",
  components: {
    ModalDefault,
  },
  props: {
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONFIG,
      dialog: false,
      menuIsActive: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

section {
  height: 82px;
}

.bSidebar {
  position: fixed;
}

.header {
  border-bottom: 1px solid var(--Greyscale-100, #dfe1e6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;

  .container {
    min-height: 82px;
  }

  &__logo {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  &__navigation {
    display: flex;
    gap: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #828282 !important;

    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    align-items: center;
    justify-content: center;

    // @media (max-width: $xl) {
    //   display: none !important;
    // }

    &__link {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: #828282;
      text-decoration: none;

      &:hover {
        color: #0d0d12;
      }
    }
  }

  &__button {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-left: auto;
    cursor: pointer;
  }

  &__burger {
    margin-left: 32px;
    width: 26px;
    height: 18px;
    display: none;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-item {
      height: 4px;
      border-radius: 10px;
      width: 100%;
      transition: all 0.4s;
      background-color: #1f2937;
      opacity: 0.56;

      &_active {
        transition: all 0.4s;

        &:nth-child(1) {
          transform: rotate(45deg) translateY(-3px);
          transform-origin: left;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translateY(3px);
          transform-origin: left;
        }
      }
    }
  }
}
</style>
