<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Что это дает</span>
      </div>

      <div class="benefit">
        <div class="row benefit__content">
          <div class="col-12 col-lg-6 mb-5">
            <div class="benefit__client" data-aos="fade-up">
              <h2>Для компаний-клиентов Лидпортал</h2>
              <div class="list-container">
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text"
                    >Новый канал с приростом продаж в десятки процентов</span
                  >
                </div>
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text"
                    >Быстрые сроки запуска (1 - 3 месяца)</span
                  >
                </div>
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text"
                    >Автоматизация и интеграция с учетом особенностей бизнеса
                    клиента</span
                  >
                </div>
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text"
                    >White-label решение - продажи под брендом клиента</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-5">
            <div class="benefit__client" data-aos="fade-up">
              <h2>Для микро-агентов</h2>
              <div class="list-container">
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text"
                    >Значимый дополнительный заработок</span
                  >
                </div>
                <div class="list-item">
                  <img
                    src="@/static/images/Icon.svg"
                    alt="icon"
                    class="list-icon"
                  />
                  <span class="list-text">Высокое удобство работы</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row benefit__content">
          <div class="col-12 col-lg-6 mb-5">
            <div class="benefit__client" data-aos="fade-up">
              <h2>Кейс 1</h2>
              <div class="list-container">
                <div class="list-item">
                  <span class="list-text"
                    >Банк, с&nbsp;помощью Лидпортал, запустил новый ипотечный
                    продукт по&nbsp;всей стране с&nbsp;минимальной сетью продаж,
                    построил агентскую сеть в&nbsp;несколько тысяч микроагентов
                    и&nbsp;вошел в&nbsp;пятерку игроков рынка по&nbsp;данному
                    продукту. 70%&nbsp;лидов приходило через Лидпортал</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-5">
            <div class="benefit__client" data-aos="fade-up">
              <h2>Кейс 2</h2>
              <div class="list-container">
                <div class="list-item">
                  <span class="list-text"
                    >Профессиональный частный риэлтор загородной недвижимости
                    выстроил продажу ипотеки, как&nbsp;часть своего ценностного
                    предложения, направляя заявки на&nbsp;ипотеку в&nbsp;банк
                    через Лидпортал, сформировав значимый дополнительный
                    денежный поток своему бизнесу и&nbsp;клиентскую
                    лояльность</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "BenefitsDefault",
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 0 0;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}

.benefit {
  max-width: 1368px;
  margin: 0 auto;

  &__content {
    // padding: 80px 0 0;
  }

  &__client {
    border: 1px solid #dfe1e6;
    border-radius: 12px;
    box-shadow: 0 1px 2px 0 rgba(13, 13, 18, 0.04),
      0 1px 3px 0 rgba(13, 13, 18, 0.05);
    background: #f8f9fb;
    padding: 35px 30px;
    min-height: 100%;

    h2 {
      margin-bottom: 30px;
      color: #0d0d12;
      font-style: normal;

      line-height: 130%;
      font-size: 26px;
      font-weight: 400;
      @media (max-width: $xl) {
        line-height: 120%;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .list-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .list-item {
      display: flex;
      gap: 20px;
    }

    .list-text {
      color: #666d80;
      line-height: 120%;

      font-size: 18px;
      @media (max-width: $xl) {
        font-size: 14px;
      }
    }
  }
}
</style>
