<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>О компании</span>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-12 col-lg-6 mb-5">
          <p class="mb-0">
            Компания Лидпортал занимается разработкой, внедрением и
            сопровождением программного обеспечения и специализируется на
            оказании B2B услуг с помощью SaaS-решения с использованием
            собственной разработки «Платформа Лидпортал» для партнеров и агентов
            по рекомендации и приобретению партнёрских продуктов за
            вознаграждение.
          </p>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <p class="mb-0">
            Решение “Платформа Лидпортал” - модульная платформа для глубокой
            white label кастомизации под партнера - зарегистрировано в Едином
            реестре российских программ для электронных вычислительных машин и
            баз данных (Реестровая запись №24128 от 23.09.2024)
          </p>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 0 0;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}

h1 {
  color: #0d0d12;
  line-height: 120%;

  font-size: 36px;
  font-weight: 600;
  @media (max-width: $xl) {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
