<template>
  <section>
    <v-container>
      <div class="contact__block" data-aos="fade-up">
        <h1>Заинтересованы в сотрудничестве?</h1>
        <!-- <p>
          Здесь нужен еще какой-то <br />
          текст для привлечени внимания
        </p> -->

        <v-dialog v-model="dialog" max-width="582">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="contact__button"
              elevation="0"
            >
              Связаться с нами
            </v-btn>
          </template>
          <ModalDefault @close="dialog = false" />
        </v-dialog>

        <v-dialog v-model="dialog2" max-width="582">
          <ModalResult @close="dialog2 = false" />
        </v-dialog>
      </div>
    </v-container>
  </section>
</template>

<script>
import { ModalDefault } from "@/components/modals"
import { ModalResult } from "@/components/modals"

export default {
  name: "ContactComponent",
  components: { ModalDefault, ModalResult },
  data() {
    return {
      dialog: false,
      dialog2: false,
    }
  },
  methods: {
    showModal() {
      this.dialog2 = false
      this.dialog = true
    },
    showModalResult() {
      this.dialog = false
      this.dialog2 = true
    },
  },
  mounted() {
    this.$root.$on("show-modal", this.showModal)
    this.$root.$on("show-modal-result", this.showModalResult)
  },
  destroyed() {
    this.$root.$off("show-modal", this.showModal)
    this.$root.$off("show-modal-result", this.showModalResult)
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 100px 0;
  @media (max-width: $xl) {
    padding: 0px 0 50px 0;
  }
}

.contact {
  &__block {
    border-radius: 16px;
    background: #377dff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;

    padding: 96px 88px;
    @media (max-width: $xl) {
      padding: 48px 44px;
    }

    h1 {
      letter-spacing: -0.01em;
      font-weight: 400;

      padding-bottom: 32px;
      font-size: 52px;
      line-height: 120%;
      @media (max-width: $xl) {
        font-size: 22px;
        line-height: 120%;
        padding-bottom: 16px;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */

      margin-bottom: 40px;

      font-size: 18px;
      @media (max-width: $xl) {
        font-size: 14px;
      }
    }
  }

  &__button {
    padding: 14px 20px;

    border-radius: 12px;
    background: var(--Greyscale-0, #fff);

    color: var(--Greyscale-900, #0d0d12);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
}
</style>
