<template>
  <section>
    <v-container>
      <div class="stats" data-aos="fade-up">
        <div class="row">
          <div class="col-12 col-sm-5 stats__left">
            <img src="@/static/images/img-l.svg" />
          </div>
          <div class="col-12 col-sm stats__right">
            <img src="@/static/images/img-r.svg" />
          </div>
        </div>
        <!-- <img src="@/static/images/Illustration.svg" class="stats__img" /> -->
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "StatsComponent",
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 0 0 100px;
  @media (max-width: $xl) {
    padding: 0 0 100px;
  }
}

.stats {
  max-width: 1160px;
  margin: 0 auto;

  &__left {
    display: flex;

    align-items: flex-start;
    justify-content: center;
    padding: 56px 16px 16px;
    background: var(--Primary-200, #372c8f);

    max-width: percentage(450 / 1160);
    border-radius: 20px 0 0 20px;
    @media (max-width: $sm) {
      border-radius: 20px 20px 0 0;
      max-width: 100%;
    }
  }

  &__right {
    display: flex;

    align-items: flex-start;
    justify-content: center;
    padding: 56px 16px 16px;
    background: var(--Primary-200, #372c8f);
    background: #377dff;

    border-radius: 0 20px 20px 0;
    @media (max-width: $sm) {
      border-radius: 0 0 20px 20px;
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
