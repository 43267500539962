<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Сколько это стоит</span>
      </div>

      <div class="price" data-aos="fade-up">
        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2">
            <div class="row no-gutters">
              <div class="col-12 col-lg-4 price__image">
                <img src="@/static/images/iphone.png" />
              </div>
              <div class="col-12 col-lg-8">
                <div class="price__item ml-lg-10">
                  <div class="price__item__icon">
                    <img src="@/static/images/ic1.svg" />
                  </div>
                  <div class="price__item__title">Запуск бесплатен</div>
                  <div class="price__item__text">
                    Вы платите только за новые продажи агентам. Наши тарифы
                    -&nbsp;это %&nbsp;от&nbsp;продаж через данный канал
                  </div>
                </div>

                <div class="price__item ml-lg-10">
                  <div class="price__item__icon">
                    <img src="@/static/images/ic2.svg" />
                  </div>
                  <div class="price__item__title">Индивидуальные тарифы</div>
                  <div class="price__item__text">
                    Конкретные условия вознаграждения для&nbsp;вашего рынка
                    и&nbsp;продукта обсуждаются индивидуально. Отправьте нам
                    ваш&nbsp;кейс
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PriceComponent",
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 0 0;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}
.price {
  &__image {
    border-radius: 12px;
    background: var(--Primary-100, #5b49e9);
    text-align: center;
    padding-top: 34px !important;
    padding-bottom: 0;
    margin-bottom: 16px !important;

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  &__item {
    border-radius: 12px;
    border: 1px solid var(--Greyscale-100, #dfe1e6);
    background: var(--Greyscale-0, #fff);
    padding: 24px;
    margin-bottom: 16px;

    &__icon {
      border-radius: 10px;
      border: 1px solid var(--Primary-50, #bdb6f6);
      background: var(--Primary-0, #efedfd);
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }

    &__title {
      color: var(--Greyscale-900, #0d0d12);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      margin-bottom: 8px;
    }

    &__text {
      color: var(--Greyscale-500, #666d80);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 27px */
    }
  }
}
</style>
