<template>
  <section>
    <v-container>
      <div class="bTitle">
        <span>Наши клиенты</span>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-12 col-sm col-lg mb-5">
          <img src="@/static/images/logo/2.svg" />
        </div>
        <div class="col-12 col-sm col-lg mb-5">
          <img src="@/static/images/logo/4.svg" />
        </div>
        <div class="col-12 col-sm col-lg mb-5">
          <img src="@/static/images/logo/5.svg" />
        </div>
        <div class="col-12 col-sm col-lg mb-5">
          <img src="@/static/images/logo/1.svg" />
        </div>
        <div class="col-12 col-sm col-lg mb-5">
          <img src="@/static/images/logo/3.svg" />
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "ClientsComponent",
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

section {
  padding: 100px 0 0 0;
  @media (max-width: $xl) {
    padding: 0 0 80px 0;
  }
}

h1 {
  color: #0d0d12;
  line-height: 120%;

  font-size: 36px;
  font-weight: 600;
  @media (max-width: $xl) {
    font-size: 18px;
    font-weight: 500;
  }
}

img {
  min-width: 200px;
  margin: 0 auto;
  display: block;
}
</style>
